<template>
  <div>
    <heads :msg="title_msg"></heads>
    <div class="main">
      <div class="code_login">
        <div class="phone">
          <input
            v-model="phone"
            class="phone_inp"
            type="text"
            placeholder="请设置用户名"
          >
        </div>
        <div class="phone">
          <input
            v-model="phone"
            class="phone_inp"
            type="text"
            placeholder="请设置密码"
          >
        </div>
      </div>
    </div>
    <div class="btn">
      <button
        :class="{ nexts: mydisabled, nexts_red: !mydisabled }"
        :disabled="mydisabled"
        @click="next_create()"
      >
        下一步
      </button>
    </div>
  </div>
</template>

<script>
import heads from '@/compontens/heads.vue'
export default {
  components: {
    heads
  },
  data() {
    return {
      title_msg: '创建账户',
      ucode: ''
    }
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.main {
}
.nexts {
  display: block;
  width: 92%;
  height: 100px;
  margin: 50px 0px 0 30px;
  font-size: 32px;
  letter-spacing: 3px;
  color: #fff;
  line-height: 44px;
  text-align: center;
  background: #f57f7f;
  border-radius: 5px;
}
.nexts_red {
  display: block;
  width: 92%;
  height: 100px;
  margin: 50px 0px 0 30px;
  font-size: 32px;
  letter-spacing: 3px;
  color: #fff;
  line-height: 44px;
  text-align: center;
  background: #ee2e2e;
  border-radius: 5px;
}
.code_login {
  width: 92%;
  margin: 0 4%;
  margin-top: 55px;
  height: 177px;
  background-color: #ffffff;
  border-radius: 10px;
  border: solid 1px #dcdcdc;
  .phone {
    height: 88px;
    font-size: 32px;
    line-height: 88px;
    border-bottom: solid 1px #dcdcdc;
    width: 100%;
    .diqu {
      width: 150px;
      float: left;
      border-right: 3px solid #dcdcdc;
      overflow: hidden;
      margin-top: 25px;
      height: 40px;
      input {
        float: left;
        width: 50%;
        font-size: 30px;
        margin-left: 20px;
        height: 40px;
        line-height: 4%;
      }
      img {
        float: left;
        width: 26px;
        margin-top: 15px;
      }
    }
    .phone_inp {
      width: 50%;
      height: 50px;
      line-height: 50px;
      margin-top: 20px;
      margin-left: 5%;
    }
  }
  .code_box {
    .code_m {
      width: 70%;
      float: left;
      height: 70px;
      margin-top: 10px;
      border-right: 2px solid #dcdcdc;
      input {
        line-height: 70px;
        margin-left: 20px;
        font-size: 32px;
      }
    }
    .get_code {
      float: left;
      color: #ee2e2e;
      font-size: 32px;
      width: 28%;
      text-align: center;
      line-height: 88px;
    }
    .get_code1 {
      float: left;
      color: #333333;
      font-size: 32px;
      width: 28%;
      text-align: center;
      line-height: 88px;
    }
  }
}
</style>
